import * as React from "react";
import Link, { LinkProps } from "next/link";
import { WithChildrenAndClassName } from "../types/react";

// `as` is used for polymorpic components
export type UnstyledLinkProps = WithChildrenAndClassName<
	Omit<LinkProps, "as"> & { linkAs?: LinkProps["as"]; external?: boolean }
>;

export const UnstyledLink = React.forwardRef<
	HTMLAnchorElement,
	UnstyledLinkProps
>(({ children, className, external, ...props }, ref) => (
	<Link {...props}>
		<a
			className={className}
			target={external ? "_blank" : "_self"}
			rel={external ? "noreferrer" : ""}
			ref={ref}
		>
			{children}
		</a>
	</Link>
));

UnstyledLink.displayName = "UnstyledLink";
