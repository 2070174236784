import clsx from "clsx";
import * as React from "react";
import { ChevronRightIcon } from "icons/components";
import { UnstyledLink } from "./unstyled-link";

type Props = {
	href: string;
	variant?: "light" | "dark" | "black";
	external?: boolean;
};

export const AnchorButton: React.FC<React.PropsWithChildren<Props>> = ({
	href,
	variant = "light",
	external,
	children,
}) => {
	return (
		<UnstyledLink
			href={href}
			className={clsx(
				"flex flex-row flex-shrink-0 items-center pb-2 pt-3 px-4 font-rigBold text-sm font-bold uppercase",
				{
					["bg-white text-black"]: variant === "light",
					["bg-primary text-black"]: variant === "dark",
					["bg-black text-white"]: variant === "black",
				},
			)}
			external={external}
		>
			{children}
			<ChevronRightIcon
				className={clsx("flex-shrink-0 ml-2", {
					["text-primary"]: variant === "light",
					["text-white "]: variant === "dark",
					["text-white"]: variant === "black",
				})}
				width={14}
				height={14}
			/>
		</UnstyledLink>
	);
};
