import * as Tooltip from "@radix-ui/react-tooltip";

const TooltipButton = ({ icon, buttonContent, tooltipContent }) => {
	return (
		<Tooltip.Root>
			<Tooltip.Trigger asChild>
				<button
					type="button"
					className="flex items-center gap-2 p-2 bg-white rounded"
				>
					{icon}
					{buttonContent}
				</button>
			</Tooltip.Trigger>
			<Tooltip.Content className="px-4 py-3 bg-white rounded shadow">
				<Tooltip.Arrow className="text-white fill-current" />
				<p>{tooltipContent}</p>
			</Tooltip.Content>
		</Tooltip.Root>
	);
};

export default TooltipButton;
