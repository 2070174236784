import * as React from "react";
import { debounce } from "ts-debounce";

type LocationInfo = {
	offsetTopPercentage: number;
	offsetTopPixels: number;
	offsetBottomPixels: number;
};

type WindowSize =
	| { width: undefined; height: undefined }
	| { width: number; height: number };

export function useElementLocationInfo(
	ref: React.RefObject<HTMLElement>,
): LocationInfo {
	const [windowSize, setWindowSize] = React.useState<WindowSize>({
		width: undefined,
		height: undefined,
	});
	const [offsetTopPercentage, setOffsetTopPercentage] = React.useState(0);
	const [offsetTopPixels, setOffsetTopPixels] = React.useState(0);
	const [offsetBottomPixels, setOffsetBottomPixels] = React.useState(0);

	React.useEffect(() => {
		const resizeObserver = new ResizeObserver(
			(entries: ResizeObserverEntry[]) =>
				setWindowSize({
					width: entries[0].target.clientWidth,
					height: entries[0].target.clientHeight,
				}),
		);
		resizeObserver.observe(document.body);
	}, []);

	React.useEffect(() => {
		if (!ref.current) {
			return;
		}

		const containerRect = ref.current!.getBoundingClientRect();
		const scrollTop = document.documentElement.scrollTop;
		const windowHeight = document.documentElement.clientHeight;
		const offsetTop = containerRect.top + scrollTop;

		setOffsetTopPercentage(offsetTop / document.body.clientHeight);
		setOffsetTopPixels(offsetTop);
		setOffsetBottomPixels(offsetTop + containerRect.height + windowHeight);
	}, [ref, windowSize]);

	return {
		offsetTopPercentage,
		offsetTopPixels,
		offsetBottomPixels,
	};
}

export function useWindowSize(debounceTime = 100): WindowSize {
	// Initialize state with undefined width/height so server and client renders match
	// Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
	const [windowSize, setWindowSize] = React.useState<WindowSize>({
		width: undefined,
		height: undefined,
	});

	React.useEffect(() => {
		window.addEventListener(
			"resize",
			debounce(() => {
				setWindowSize({
					width: window.innerWidth,
					height: window.innerHeight,
				});
			}, debounceTime),
		);

		debounce(() => {
			setWindowSize({
				width: window.innerWidth,
				height: window.innerHeight,
			});
		}, debounceTime)();

		return () =>
			window.removeEventListener(
				"resize",
				debounce(() => {
					setWindowSize({
						width: window.innerWidth,
						height: window.innerHeight,
					});
				}, debounceTime),
			);
	}, [debounceTime]);

	return windowSize;
}

export function useDocumentHeight(defaultHeight = 0): number {
	const [documentHeight, setDocumentHeight] =
		React.useState<number>(defaultHeight);

	React.useEffect(() => {
		setDocumentHeight(document.documentElement.clientHeight);
	}, []);

	return documentHeight;
}
