import * as React from "react";
import GoogleMapReact from "google-map-react";
import Container from "@components/container";
import type { LocationMap as RootLocationMap } from "@/types/generated/schema";
import { BaronLogoFlagMap } from "@components/baron-logo-flag";

export type MapProps = Omit<RootLocationMap, "_type"> & {
	zoom?: number;
};

const LocationMap = ({ location, zoom = 15 }: MapProps) => {
	if (!location) {
		return null;
	}

	return (
		<Container>
			<div className="h-96">
				<GoogleMapReact
					bootstrapURLKeys={{
						key: process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY,
					}}
					zoom={zoom}
					defaultCenter={{ lat: location.lat, lng: location.lng }}
				>
					<div
						className="w-10 h-14 -translate-x-5 -translate-y-[28px]"
						/* @ts-ignore */
						lat={location.lat}
						lng={location.lng}
					>
						<BaronLogoFlagMap />
					</div>
				</GoogleMapReact>
			</div>
		</Container>
	);
};

export default LocationMap;
