import * as React from "react";
import type * as Polymorphic from "@radix-ui/react-polymorphic";

type PolymorphicBox = Polymorphic.ForwardRefComponent<"div", unknown>;

export const Box: PolymorphicBox = React.forwardRef(
	({ as: Comp = "div", ...props }, forwardedRef) => (
		<Comp {...props} ref={forwardedRef} />
	),
);

Box.displayName = "Box";
