export enum Status {
	PROSPECT = "PROSPECT",
	SOON = "IN_AANMELDING",
	AVAILABLE = "BESCHIKBAAR",
	UNDER_OFFER = "ONDER_BOD",
	UNDER_OPTION = "ONDER_OPTIE",
	SOLD_UNDER_CONDITION = "VERKOCHT_ONDER_VOORBEHOUD",
	RENTED_UNDER_CONDITION = "VERHUURD_ONDER_VOORBEHOUD",
	SOLD = "VERKOCHT",
	RENTED = "VERHUURD",
	AUCTIONED = "GEVEILD",
	CANCELLED = "GEANNULEERD",
	SOLD_BY_REGISTRATION = "VERKOCHT_BIJ_INSCHRIJVING",
}

export const statusOptions: { [key in Status]: string } = {
	[Status.PROSPECT]: "Prospect",
	[Status.SOON]: "In aanmelding",
	[Status.AVAILABLE]: "Beschikbaar",
	[Status.UNDER_OFFER]: "Onder bod",
	[Status.UNDER_OPTION]: "Onder optie",
	[Status.SOLD_UNDER_CONDITION]: "Verkocht onder voorbehoud",
	[Status.RENTED_UNDER_CONDITION]: "Verhuurd onder voorbehoud",
	[Status.SOLD]: "Verkocht",
	[Status.RENTED]: "Verhuurd",
	[Status.AUCTIONED]: "Geveild",
	[Status.CANCELLED]: "Geannuleerd",
	[Status.SOLD_BY_REGISTRATION]: "Verkocht bij inschrijving",
};

export const acceptanceOptions: { [key: string]: string } = {
	IN_OVERLEG: "In overleg",
	PER_DATUM: "Per datum",
	DIRECT: "Direct",
};

export const propertyCategoryOptions: { [key: string]: string } = {
	NIEUWBOUW: "Nieuwbouw",
	BOUWGROND: "Bouwgrond",
	WOONHUIS: "Woonhuis",
	OVERIGOG: "Overig OG",
	APPARTEMENT: "Appartement",
};

export const cnTypeOptions: { [key: string]: string } = {
	BENEDEN_PLUS_BOVENWONING: "Beneden + bovenwoning",
	BENEDENWONING: "Benedenwoning",
	BOVENWONING: "Bovenwoning",
	DUBBEL_BENEDENHUIS: "Dubbel benedenhuis",
	EINDWONING: "Eindwoning",
	GALERIJFLAT: "Galerijflat",
	GESCHAKELDE_TWEE_ONDER_EEN_KAPWONING: "Geschakelde twee-onder-één-kapwoning",
	GESCHAKELDE_WONING: "Geschakelde woning",
	HALFVRIJSTAANDE_WONING: "Halfvrijstaande woning",
	HOEKWONING: "Hoekwoning",
	MAISONNETTE: "Maisonnette",
	PENTHOUSE: "Penthouse",
	PORTIEKFLAT: "Portiekflat",
	PORTIEKWONING: "Portiekwoning",
	STUDENTENKAMER: "Studentenkamer",
	TUSSENVERDIEPING: "Tussenverdieping",
	TUSSENWONING: "Tussenwoning",
	TWEE_ONDER_EEN_KAPWONING: "Twee-onder-één-kapwoning",
	VERSPRINGEND: "Verspringend",
	VRIJSTAANDE_WONING: "Vrijstaande woning",
	NIEUWBOUW: "Nieuwbouw",
	BESTAANDE_BOUW: "Bestaande bouw",
	NIET_VAN_TOEPASSING: "Niet van toepassing",
};

export const specialOptions: { [key: string]: string } = {
	MONUMENTAAL_PAND: "Monumentaal pand",
	MONUMENT: "Monument",
	GESTOFFEERD: "Gestoffeerd",
	GEDEELTELIJK_GESTOFFEERD: "Gedeeltelijk gestoffeerd",
	GEDEELTELIJK_VERHUURD: "Gedeeltelijk verhuurd",
	GEMEUBILEERD: "Gemeubileerd",
	DUBBELE_BEWONING_MOGELIJK: "Dubbele bewoning mogelijk",
	DUBBELE_BEWONING_AANWEZIG: "Dubbele bewoning aanwezig",
	BESCHERMD_STADS_OF_DORPSGEZICHT: "Beschermd stads- of dorpsgezicht",
	TOEGANKELIJK_VOOR_OUDEREN: "Toegankelijk voor ouderen",
	TOEGANKELIJK_VOOR_MINDER_VALIDEN: "Toegankelijk voor minder validen",
	KLUSWONING: "Kluswoning",
	SLOOPWONING: "Sloopwoning",
	ERFGOED: "Erfgoed",
};

/**
 * options are:
 * DAKISOLATIE, MUURISOLATIE, VLOERISOLATIE, DUBBEL_GLAS, GEDEELTELIJK_DUBBEL_GLAS, VOORZETRAMEN, VOLLEDIG_GEISOLEERD, GEEN_SPOUW, ECO_BOUW, GEEN_ISOLATIE, GROTENDEELS_DUBBELGLAS, DRIEDUBBEL_GLAS, KIERDICHTING, HR_GLAS
 */
export const isolationOptions: { [key: string]: string } = {
	DAKISOLATIE: "Dakisolatie",
	MUURISOLATIE: "Muurisolatie",
	VLOERISOLATIE: "Vloerisolatie",
	DUBBEL_GLAS: "Dubbel glas",
	GEDEELTELIJK_DUBBEL_GLAS: "Gedeeltelijk dubbel glas",
	VOORZETRAMEN: "Voorzetramen",
	VOLLEDIG_GEISOLEERD: "Volledig geïsoleerd",
	GEEN_SPOUW: "Geen spouw",
	ECO_BOUW: "Eco bouw",
	GEEN_ISOLATIE: "Geen isolatie",
	GROTENDEELS_DUBBELGLAS: "Grotendeels dubbelglas",
	DRIEDUBBEL_GLAS: "Driedubbel glas",
	KIERDICHTING: "Kierdichting",
	HR_GLAS: "HR glas",
};

/**
 * options are:
 * CV_KETEL, CENTRALE_VOORZIENING, GEISER_HUUR, GEISER_EIGENDOM, GASBOILER_HUUR, GASBOILER_EIGENDOM, ELEKTRISCHE_BOILER_HUUR, ZONNEBOILER, ZONNECOLLECTOREN, ELEKTRISCHE_BOILER_EIGENDOM, GEEN, STADSVERWARMING, DOORSTROOMBOILER, AARDWARMTE
 */
export const heatingOptions: { [key: string]: string } = {
	CV_KETEL: "CV ketel",
	CENTRALE_VOORZIENING: "Centrale voorziening",
	GEISER_HUUR: "Geiser huur",
	GEISER_EIGENDOM: "Geiser eigendom",
	GASBOILER_HUUR: "Gasboiler huur",
	GASBOILER_EIGENDOM: "Gasboiler eigendom",
	ELEKTRISCHE_BOILER_HUUR: "Elektrische boiler huur",
	ZONNEBOILER: "Zonneboiler",
	ZONNECOLLECTOREN: "Zonnecollectoren",
	ELEKTRISCHE_BOILER_EIGENDOM: "Elektrische boiler",
	GEEN: "Geen",
	STADSVERWARMING: "Stadsverwarming",
	DOORSTROOMBOILER: "Doorstroomboiler",
	AARDWARMTE: "Aardwarmte",
};

export const liggingOptions: { [key: string]: string } = {
	AAN_BOSRAND: "Aan bosrand",
	AAN_WATER: "Aan water",
	AAN_PARK: "Aan park",
	AAN_DRUKKE_WEG: "Aan drukke weg",
	AAN_RUSTIGE_WEG: "Aan rustige weg",
	IN_CENTRUM: "In centrum",
	IN_WOONWIJK: "In woonwijk",
	VRIJ_UITZICHT: "Vrij uitzicht",
	BESCHUTTE_LIGGING: "Beschutte ligging",
	OPEN_LIGGING: "Open ligging",
	BUITEN_BEBOUWDE_KOM: "Buiten bebouwde kom",
	AAN_VAARWATER: "Aan vaarwater",
	IN_BOSRIJKE_OMGEVING: "In bosrijke omgeving",
	LANDELIJK_GELEGEN: "Landelijk gelegen",
	ZEEZICHT: "Zeezicht",
	BEDRIJVENTERREIN: "Bedrijventerrein",
};

export const tuinOptions: { [key: string]: string } = {
	ACHTERTUIN: "Achtertuin",
	VOORTUIN: "Voortuin",
	ZIJTUIN: "Zijtuin",
	TUIN_RONDOM: "Tuin rondom",
	PATIO_ATRIUM: "Patio/atrium",
	PLAATS: "Plaats",
	GEEN_TUIN: "Geen tuin",
	ZONNETERRAS: "Zonneterras",
};

export const bergingOptionsSoort: { [key: string]: string } = {
	AANGEBOUWD_STEEN: "Aangebouwd steen",
	AANGEBOUWD_HOUT: "Aangebouwd hout",
	VRIJSTAAND_STEEN: "Vrijstaande stenen berging",
	VRIJSTAAND_HOUT: "Vrijstaande houten berging",
	INPANDIG: "Inpandig",
	BOX: "Box",
	VRIJSTAAND_KUNSTSTOF: "Vrijstaande kunststof berging",
	ONBEKEND: "Onbekend",
};

export const bergingVoorzieningen: { [key: string]: string } = {
	VOORZIEN_VAN_VERWARMING: "Verwarming",
	VOORZIEN_VAN_ELEKTRA: "Elektra",
	VOORZIEN_VAN_WATER: "Water",
	MET_VLIERING: "Met vliering",
};

export function formatBathroomAmentities(arr: string[] = []) {
	const ignoredAmentities = ["WASTAFEL", "INLOOPDOUCHE", "VLOERVERWARMING"];

	arr = arr
		.sort()
		.filter((item) => !ignoredAmentities.includes(item))
		.map((item) => item.toLowerCase().replace(/_/g, " "));

	if (arr.length === 0) {
		return "";
	}

	arr[0] = arr[0].charAt(0).toUpperCase() + arr[0].slice(1);

	const formatter = new Intl.ListFormat("nl", {
		style: "long",
		type: "conjunction",
	});

	return formatter.format(arr);
}

/**
 * All possible options
 * OPENBAAR_PARKEREN, BETAALD_PARKEREN, PARKEERGARAGE, PARKEERVERGUNNINGEN, OP_EIGEN_TERREIN, OP_AFGESLOTEN_TERREIN
 *
 * OP_EIGEN_TERREIN should be first
 * PARKEERGARAGE should be second
 * @param a
 * @param b
 */
const sortParkingOptions = (a: string, b: string) => {
	if (a === "OP_EIGEN_TERREIN") return -1;
	if (b === "OP_EIGEN_TERREIN") return 1;
	if (a === "OP_AFGESLOTEN_TERREIN") return -1;
	if (b === "OP_AFGESLOTEN_TERREIN") return 1;
	if (a === "PARKEERGARAGE") return -1;
	if (b === "PARKEERGARAGE") return 1;
	return 0;
};

export function formatOptions(arr: string[] = []) {
	arr = arr
		.sort(sortParkingOptions)
		.map((item) => item.toLowerCase().replace(/_/g, " "));

	if (arr.length === 0) {
		return "";
	}

	arr[0] = arr[0].charAt(0).toUpperCase() + arr[0].slice(1);

	const formatter = new Intl.ListFormat("nl", {
		style: "long",
		type: "conjunction",
	});

	return formatter.format(arr);
}

export const propertyTypeOptions: { [key: string]: string } = {
	"bottom-part": "Onderstuk",
	"canal-house": "Grachtenpand",
	"country-house": "Landhuis",
	"dorm-room": "Studentenkamer",
	"double-ground-floor-apartment": "Dubbel benedenhuis",
	"gallery-flat": "Galerijflat",
	"ground-floor-apartment": "Benedenwoning",
	"ground-floor-flat-top-floor": "Beneden + bovenwoning",
	"indoor-garage": "InpandigeGarage",
	"mobile-home-putch": "Stacaravanstandplaats",
	"mobile-home": "Stacaravan",
	"parking-garage": "Parkeer kelder",
	"parking-place": "Parkeerplaats / Capaciteit",
	"porch-flat": "Portiekflat",
	"single-family-house": "Eengezinswoning",
	"storage-space": "Opslagruimte",
	"top-floor": "Bovenwoning",
	"trailer-park-location": "Woonwagenstandplaats",
	bert: "Ligplaats",
	bungalow: "Bungalow",
	caravan: "Woonwagen",
	duplex: "Maisonnette",
	estate: "Landgoed",
	farmhouse: "Woonboerderij",
	garage: "Garage",
	gatehouse: "Portiekwoning",
	houseboat: "Woonboot",
	mansion: "Herenhuis",
	mezzanine: "Tussenverdieping",
	penthouse: "Penthouse",
	salvage: "Berging",
	villa: "Villa",
};

export function translateRentalCondition(condition: string) {
	switch (condition) {
		case "monthly":
			return "p.m.";
		case "yearly":
			return "p.j.";
		default:
			return "";
	}
}

export function translatePriceCondition(condition: string) {
	switch (condition) {
		case "kk":
			return "K.K.";
		case "von":
			return "V.O.N.";
		default:
			return "";
	}
}
