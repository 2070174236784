import clsx from "clsx";
import * as React from "react";

const Container: React.FC<React.PropsWithChildren<{ className?: string }>> = ({
	className,
	children,
}): JSX.Element => {
	return (
		<div
			className={clsx(
				"flex flex-col m-auto px-6 py-10 max-w-7xl md:py-16",
				className,
			)}
		>
			{children}
		</div>
	);
};

export default Container;
