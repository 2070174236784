const widthDescriptorRegex = /\d+(w|x)$/;
export function getSrcSet(media): string {
	let srcSet = "";

	for (const key of Object.keys(media)) {
		if (widthDescriptorRegex.test(key)) {
			srcSet += `${srcSet ? ", " : ""}${media[key]} ${key}`;
		}
	}

	return srcSet;
}

export function getSizes(media): string {
	const widthKeys = Object.keys(media).filter((key) => key.endsWith("w"));
	const sortedWidths = widthKeys
		.map((key) => parseInt(key, 10))
		.sort((a, b) => a - b);

	let sizes = sortedWidths
		.map((width, index) => {
			// For the largest size, we don't specify a max-width
			if (index === sortedWidths.length - 1) {
				return `${width}px`;
			}
			// Calculate the breakpoint as the midpoint between this width and the next larger width
			const breakpoint = Math.floor((width + sortedWidths[index + 1]) / 2);
			return `(max-width: ${breakpoint}px) ${width}px`;
		})
		.join(", ");

	return sizes;
}
