import clsx from "clsx";
import * as React from "react";
import { Box } from "./box";

type Props = {
	as?: "span" | "p" | "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
	className?: string;
	size?: "small" | "large" | "xlarge";
};

const Heading: React.FC<React.PropsWithChildren<Props>> = ({
	as = "h3",
	size = "large",
	className,
	children,
}): JSX.Element => {
	return (
		<Box
			as={as}
			className={clsx(
				className,
				"font-bold uppercase",
				size === "small" && "text-sm",
				size === "large" && "text-lg",
				size === "xlarge" && "text-2xl",
			)}
		>
			{children}
		</Box>
	);
};

export default Heading;
